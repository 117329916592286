import(/* webpackMode: "eager", webpackExports: ["SimulatePreloadedQuery"] */ "/app/node_modules/@apollo/experimental-nextjs-app-support/node_modules/@apollo/client-react-streaming/dist/index.cc.js");
;
import(/* webpackMode: "eager", webpackExports: ["Banner"] */ "/app/src/app/br/banner.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/icons/apple-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/icons/play-store.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/app/app-collaborators-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/app/app-home-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/conexa.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/queima-diaria-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/smart-coach-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/total-play-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/card-item-icon/zen-app-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/exclusive-content-benefits.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/meal-plan-benefits.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalfit-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalfood-benefit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalmind-benefit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/benefits/totalnutri-benefit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/gym-search/gym-search-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/app-totalpass.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/client-tp.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/company-contact.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/contact.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/dealing.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/estimate.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/first-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/form.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/megaphone.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/second-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/select-plan.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/third-step.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/how-it-works/workOut.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/canais-indiretos-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/collaborators-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/gyms-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/information/home-background.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/beach-tennis.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/cross-training.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/dancas.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/eletroestimulacao.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/lutas.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/massagem.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/musculacao.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/natacao.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/pilates.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/volei.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/modalities/yoga.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/bio-ritmo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/buddha-spa-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/check-bullet-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/jab-house-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/kore.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/oazi-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/our-advantages-image.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/race-bootcamp.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/sky-fit-icon.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/smart-fit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/tecfit.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/tonus-gym.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/velocity.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-advantages/vidya-icon.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/azul-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/gptw-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/itau-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-amil.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-assai.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-biolab.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-bradesco.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-burger-king.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-carrefour.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-coop.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-gerdau.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-hapvida.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-nestle.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-petrobras.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-ufra.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-via-varejo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/logo-volkswagen.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/companies/votorantim-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/bio-ritmo-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/buddha-spa-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/cross-experience-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/cross-life-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/daytona-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/evoque-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/jab-house-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/kore-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/life-lift-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/logo_academia24h.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/logo_smartfit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/moinhos-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/nation-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/race-bootcamp-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/sky-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/target-fit-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/ultra-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/velocity-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/vidya-logo.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/gyms/xprime-logo.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/biolab.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/burger-king.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/kalunga.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/moura.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/nestle.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/netflix.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/nubank.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/petrobras.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/policia-militar.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/veste.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/vivara.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/our-partners/home/vr.png");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/companies/alstom.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/companies/catho.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/companies/coop.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/companies/unimed.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/companies/volkswagen.svg");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-1repmax.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-assessoria-esportiva-romulosa.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-atrium-crossfit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-bruno-botelho.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-cross-nutrition-box.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-fernando-brancatti.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-joao-caitanom.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-lions-fitness.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-mare-alta-clube.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-matrix-fit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-rantamys.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-rrfit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-strike-fit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-studio-livinng-fit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-target-fit.webp");
;
import(/* webpackMode: "eager" */ "/app/src/assets/br/sections/testimonial/partners-gyms/logo-volt-gym.webp");
;
import(/* webpackMode: "eager", webpackExports: ["App"] */ "/app/src/components/sections/app/app.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Benefits"] */ "/app/src/components/sections/benefits/benefits.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Faq"] */ "/app/src/components/sections/faq/faq.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["GymSearch"] */ "/app/src/components/sections/gymSearch/gymSearch.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HowItWorks"] */ "/app/src/components/sections/howItWorks/howItWorks.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InformationBanner"] */ "/app/src/components/sections/informationBanner/informationBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Modalities"] */ "/app/src/components/sections/modalities/modalities.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurAdvantages"] */ "/app/src/components/sections/ourAdvantages/ourAdvantages.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["OurClients"] */ "/app/src/components/sections/ourClients/ourClients.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Plans"] */ "/app/src/components/sections/plans/plans.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Testimonials"] */ "/app/src/components/sections/testimonial/testimonial.tsx");
